import React, {ReactElement} from 'react'
import styled from 'styled-components'

const SectionHeaderStyles = styled.div`
  h3 {
    color: var(--headergreen);
    margin-bottom: 5rem;
    font-size: var(--primary-header-font-size);
    font-weight: 400;

    @media only screen and (min-width: 600px) {
      margin-bottom: 8rem;
    }
  }
`

export interface SectionHeaderProps {
  children: ReactElement | string
}

const SectionHeader = ({children}: SectionHeaderProps) => {
  return (
    <SectionHeaderStyles>
      <h3>{children}</h3>
    </SectionHeaderStyles>
  )
}

export default SectionHeader
