import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import React, {ReactElement} from 'react'
import {altTags} from './GalleryImagesAltText'

export interface Image {
  base64: string
  aspectRatio: number
  src: string
  srcSet: string
  srcSetType: string
  sizes: string
  originalImg: string
  originalName: string
}

export interface ChildImageSharp {
  thumb: Image
  full: Image
}
export interface GalleryWrapperProps {
  allFile: {
    edges: [
      node: {
        childImageSharp: ChildImageSharp
      },
    ]
  }
  count: number
}
const GalleryWrapper = ({
  allFile,
  count,
}: GalleryWrapperProps): ReactElement => {
  const pickedImages = count ? allFile.edges.slice(0, count) : allFile.edges
  const imagesWithAltTags = pickedImages.map(({node}: any) => {
    const [
      imageName,
      _extension,
    ] = node.childImageSharp.thumb.originalName.split('.')
    const alt = altTags[imageName]
    return {
      ...node.childImageSharp,
      thumbAlt: alt,
      caption: alt,
    }
  })
  return <Gallery images={imagesWithAltTags} />
}

export default GalleryWrapper
