import React, {ReactElement} from 'react'
import {Helmet} from 'react-helmet'
import {useSiteMetadata} from '../hooks/use-site-metadata'

interface SEOProps {
  description?: string
  lang?: string
  meta?: object[]
  title: string
}

function SEO({
  description = '',
  lang = 'en',
  meta = [],
  title,
}: SEOProps): ReactElement {
  const {site, garden} = useSiteMetadata()

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const metaInfo = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: garden,
    },
    {
      property: `og:url`,
      content: site.siteMetadata.siteUrl,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:image`,
      content: garden,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[...meta, ...metaInfo]}
    />
  )
}

export default SEO
