import {useStaticQuery, graphql} from 'gatsby'

export const useSiteMetadata = () => {
  const {site, garden} = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      garden: file(name: {eq: "garden"}) {
        childImageSharp {
          fixed(width: 1024) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return {site, garden}
}
