import React, {ReactElement} from 'react'
import styled from 'styled-components'

interface ContainerProps {
  children: ReactElement
}

const ContainerStyles = styled.div`
  display: block;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 3rem 2rem;

  @media only screen and (min-width: 600px) {
    max-width: 1200px;
    padding: 8rem 0;
    margin: 0 auto;
  }
`

const SlimContainerStyles = styled.div`
  display: block;
  height: 100%;
  width: 100vw;
  margin: 0 auto;
  padding: 0;

  @media only screen and (min-width: 600px) {
    max-width: 1200px;
  }
`

const Container = ({children}: ContainerProps) => (
  <ContainerStyles>{children}</ContainerStyles>
)

export const SlimContainer = ({children}: ContainerProps) => (
  <SlimContainerStyles>{children}</SlimContainerStyles>
)

export default Container
