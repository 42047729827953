export const altTags: {[key: string]: string} = {
  hero2:
    'Photograph of our shared patio area with plenty of seating to enjoy our garden.',
  no5:
    'Photograph of the front door of Number 5. One of our self catering studio cottages.',
  no4:
    'Photograph of the front door of Number 4 overlooking our courtyard. One of our self catering studio cottages.',
  no2:
    'Photograph of the front door of Number 2. Our first floor apartment is in the oldest and most quirky part of our property.',
  garden: 'Photograph of our lovely garden.',
  entrance: 'Photograph of the front archway onto Bow Street.',
  courtyard:
    'Photograph of our long courtyard running the full length of the property.',
  bench: 'Feel free to relax in our lovely garden on our bench.',
  bull: 'Our resident bull.',
  covid:
    'We are currently playing our part in the fight against Covid 19. Our accomodation is full coviud secure',
  parking:
    'Photograph overlooking our private car situated at the rear of the property. Enough space to accomodate 6+ cars',
  swing: 'We welcome little guests. They are welcome to enjoy the facilities.',
}
